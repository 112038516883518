<template>
	<div>
		<CCard class="p-4">
		<CRow>
			
			<CCol lg="6">
					<h5>Furnishing Report ({{dataList.total}}) </h5>
			</CCol>
	     		 <CCol lg="6">
					  <CButton size="sm" style="float: right;" :class="'float-lg-right block '+showCollapse ? 'collapsed' : null"
			                 :aria-expanded="showCollapse ? 'true' : 'false'"
			                 aria-controls="collapse1"
			                 @click="showCollapse = !showCollapse"
			                 color="info"><i class="fa fa-search"></i> Filter<i :class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
					<!-- <CButton 
							v-if="config.getPermission('furnishing_reports').download"
							size="sm" style="margin-right: 10px" 
							color="btn btn-outline-primary"
							@click="download"
							class="float-lg-right">
							<i class="fa fa-download">
							</i> Download
					</CButton> -->

			        <!-- <CButton 
							v-if="config.getPermission('furnishing_reports').download"
						size="sm" style="margin-right: 10px" 
						color="success" href="#/main/create_furnishing" target="_blank"
						class="float-lg-right"><i class="fa fa-plus"></i>Add Furnishing
					</CButton>  -->
		      		<CButton 
							v-if="config.getPermission('equipment_reports').download"
							size="sm" style="margin-right: 10px" 
							color="btn btn-outline-primary"
							class="float-lg-right"
							@click="() => { showModalDownloadFilter = true }">
							<i class="fa fa-download"></i> Download
					</CButton>
			    </CCol>
		</CRow>
		<CRow> 
				<CCol lg="12">
					<CCollapse id="collapse1" :show="showCollapse" class="mt-2">
				        <CCard class="p-4">
				          <Search @depot-search-query="search"/>
				        </CCard>
				      </CCollapse> 
				</CCol>
		</CRow> 
		<hr>

		<CRow>			
	      
		<CCol lg="12">  
			<div class="table-responsive-sm table-responsive-md">
	      		<CDataTable
				 	:loading="isLoading"
			      	:items="dataList.data"
			      	:fields="fields"
					hover
					striped
					outlined
					>
				<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
				</template>
			    	<!-- <template #action="{item}">
				        <td style="padding: 5px; width: 100px">
				        	<CDropdown 
				                toggler-text="Select"  
								size="sm"
				                color="info"
				            >
				            	<CDropdownItem v-if="config.getPermission('furnishing').view" @click="viewModal(item)">View</CDropdownItem>  
				            	<CDropdownItem v-if="config.getPermission('furnishing').update" @click="updateModal(item)">Update</CDropdownItem>   
				            	<CDropdownItem v-if="config.getPermission('furnishing').delete" @click="deleteItem(item)">Delete</CDropdownItem>   
				            </CDropdown>
				        </td>
			      	</template>  -->
			    </CDataTable> 
			    <CPagination
		          	:active-page.sync="currentPage"
		          	:pages="Math.ceil(dataList.total / 20)"
		          	:activePage="currentPage"
		          	@update:activePage="updatePage"
		        /> 
				<download-filter :filters="filter" :showModalDownloadFilter="showModalDownloadFilter" @closeModalDownloadFilter="closeModalDownloadFilter"/>
			</div>
	      	</CCol>
	    </CRow> 
		</CCard>
	</div>
</template>
<script> 
import config from '../config.js';
import axios from '../axios';
import Swal from 'sweetalert2';
import Search from './furnishing_report_search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'; 
import moment from 'moment';
import '../style.css';
import downloadFilter from './furnishing_filter_report.vue';

export default {
	mounted(){  
		this.getData();  
	},
	data(){
		return{
			isLoading: false,
			config,
			dataParams: {
			
			},
			// approveModal: false,
			// dispatchModal: false,
			// deliverModal: false,
			// completeModal: false,
			formModal: false,
			showViewModal: false,
			dataList: [],  
	     	depotName: "", 
			fields: [
				{
					key: 'asset_no', 
					label: 'Asset No.'
				},
				{
					key: 'description', 
					label: 'Description'
				}, 
				{
					key: 'model_label', 
					label: 'Model'
				},
				{
					key: 'type', 
					label: 'Type'
				},
				// {
				// 	key: 'acquisition_date', 
				// 	label: 'Acquisition Date'
				// },
				{
					key: 'origin_id_label', 
					label: 'Origin',
				}, 
				{
					key: 'current_location_id_label', 
					label: 'Current Location',
				}, 
				{
					key: 'depot_name', 
					label: 'Depot'
				},
				{
					key: 'status', 
					label: 'Status'
				}
				// {
				// 	key: 'action', 
				// 	label: 'Action'
				// }
			],
			currentPage: 1,  


			editMode: false,
			selectedRow: {},
			showCollapse: false,
			
			filter: {
				asset_no: "",
	            description: "",
	            type:"",
	            model:"",
	            // acquisition_date_from:"",
	            // acquisition_date_to:"",
	            current_location_id: "",
				origin_id: "",
				depot_id: "",
	            status:"",
			},
			
			customerList: {
				data: []
			},
			showModalDownloadFilter:false,
		}
	},
	name: 'Tables',
	components: {Search, Datepicker, vSelect, downloadFilter},
	methods: {
		 getBadge (status) {
    	return status === 'operational' ? 'success'
       		 : status === 'breakdown' ? 'dark'
         	 : status === 'for scrap' ? 'warning'
			 : status === 'standby' ? 'secondary'
			 : status === 'for erection' ? 'primary'
         	 : status === 'for inspection' ? 'info'
			 : status === 'dismantle' ? 'light'
			 :status === 'for rehab' ? 'success'
			 :status === 'pull-out' ? 'success'
             : status === 'reserve' ? 'danger' : 'primary'
    },
		reloadData(){
			this.getData();  
		},
		updatePage(data){
			this.currentPage = data;
			this.getData();
		},   


	    toggleModal(){
	    	this.formModal = !this.formModal;
	    }, 

	    search(event){
	    	this.filter = event; 
	    	this.getData();  
	    }, 

	    getData(){  
			this.isLoading=true;

	    	var current_location_id = this.filter.current_location_id;
	    	if(current_location_id == "all" || current_location_id == null){
	    		current_location_id = ""
	    	} 
	    	var status = this.filter.status;
	    	if(status == "all" || status == null){
	    		status = ""
	    	} 
			var depot_id = this.filter.depot_id;
	    	if(depot_id == "All" || depot_id == null){
	    		depot_id = ""
	    	} 
			var origin_id = this.filter.origin_id;
	    	if(origin_id == "All" || origin_id == null){
	    		origin_id = ""
	    	} 

	    	// if(this.filter.acquisition_date_from != ""){ 
	    	// 	this.filter.acquisition_date_from = moment(this.filter.acquisition_date_from).startOf('day') / 1000;
	    	// }

	    	// if(this.filter.acquisition_date_to != ""){ 
	    	// 	this.filter.acquisition_date_to = moment(this.filter.acquisition_date_to).startOf('day') / 1000;
	    	// 	this.filter.acquisition_date_to += 86399;
	    	// }
	
	    	axios.get(config.api_path+'/furnishing?asset_no='+this.filter.asset_no
			+'&description='+this.filter.description
			+'&current_location_id='+current_location_id
			+'&depot_id='+depot_id
			+'&origin_id='+origin_id
			+'&type='+this.filter.type+'&model='+this.filter.model
			// +'&acquisition_date_from='+this.filter.acquisition_date_from
			// +'&acquisition_date_to='+this.filter.acquisition_date_to
			+'&status='+status+'&page='+this.currentPage+'&limit=20')
	    	.then(response => {
	    		this.dataList = response.data; 
	    		this.dataList.data = this.dataList.data.map((value, index)=>{ 
					value.origin_id_label = value?.origin?.customer_name ?? '';
	    			value.asset_no = value.asset_no && value.asset_no != "null" ? value.asset_no : "N/A";
	    			value.late_transfer_date = value.late_transfer_date ? moment.unix(value.late_transfer_date).format('MMMM DD, YYYY') : "N/A";
	    			value.acquisition_date = value.acquisition_date ? moment.unix(value.acquisition_date).format('MM/DD/YYYY') : "N/A";

					
 					if(value.model == "" || value.model== null){
	    				value.model = "N/A";
	    			} 
 					
					if(value.current_location_id == null ){
 						value.current_location_id_label  = "N/A";
 					}
					 else{
	    				value.current_location_id_label = value.current_location.customer_name
	    			}  
					value.description = value?.description ? this.$options.filters.truncate(value.description):'-';
					value.depot_name = value?.depot?.setting_name ?? '-';

 					if(value.model == null ){
 						value.model_label  = "N/A";
 					}
					 else{
	    				value.model_label = value.model
	    			}  

	    			return value;
	    		}); 
	    	})
			.catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})
	    },

	    
	    updateModal(item){ 
	    	this.$router.push('/main/update_furnishing/'+item.id) 
	    },

	    viewModal(item){ 
	    	this.$router.push('/main/view_furnishing/'+item.id) 
	    },

	    // deleteRow(item){
	    // 	Swal.fire({
		// 		title: 'Are you sure you want to delete this furnishing?', 
		// 		showCancelButton: true,
		// 		confirmButtonText: `Yes`, 
		// 	}).then((result) => { 
		// 		if (result.isConfirmed) {
		// 			axios.delete(config.api_path+'/furnishing/'+item.id)
		// 	    	.then(response => {
		// 	    		this.getData(); 
		// 	    		Swal.fire({
		// 					title: 'Success!',
		// 					text: "Furnishing successfully deleted",
		// 					icon: 'success', 
		// 				})
		// 	    	}) 
		// 		}
		// 	})  
	    // }, 

	  
		
	    download(){  

    		var current_location_id = this.filter.current_location_id;
	    	if(current_location_id == "all" || current_location_id == null){
	    		current_location_id = ""
	    	} 
	    	var status = this.filter.status;
	    	if(status == "all" || status == null){
	    		status = ""
	    	} 

	    	if(this.filter.acquisition_date_from != ""){ 
	    		this.filter.acquisition_date_from = moment(this.filter.acquisition_date_from).startOf('day') / 1000;
	    	}

	    	if(this.filter.acquisition_date_to != ""){ 
	    		this.filter.acquisition_date_to = moment(this.filter.acquisition_date_to).startOf('day') / 1000;
	    		this.filter.acquisition_date_to += 86399;
	    	}

			this.$showLoading(true)
	
	    	axios.get(config.api_path+'/reports/furnishing?asset_no='+this.filter.asset_no+'&description='+this.filter.description+'&current_location_id='+current_location_id+'&type='+this.filter.type+'&model='+this.filter.model+'&acquisition_date_from='+this.filter.acquisition_date_from+'&acquisition_date_to='+this.filter.acquisition_date_to+'&status='+status)
	    	.then(response => {
				this.$showLoading(false)
	    		window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file) 

				
				setTimeout(() => {
					axios.get(`${config.api_path}/remove-file`,{
						params : {
							filename : response.data.file
						}
					})	
				}, 2000);

	    	})
			.catch(err => {
				this.$showLoading(false)
			})

	    },
		closeModalDownloadFilter(status){
			this.showModalDownloadFilter = status;
		},
	}


	//     deleteItem(item){
	//     	axios.delete(config.api_path+'/furnishing/'+item.id)
	//     	.then(response => {
	//     		Swal.fire({
	// 				title: 'Success!',
	// 				text: "Furnishing successfully deleted",
	// 				icon: 'success', 
	// 			})
	// 			this.getData();	
	//     	})
	//     }
 

 	// }
}
</script>
